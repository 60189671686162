import React, { useState } from "react";
import axios from "axios";

const IndexPage = () => {
  const [disableButtonPreview, setDisableButtonPreview] = useState(false);
  const [disableButtonBuild, setDisableButtonBuild] = useState(false);

  async function sendPreview() {
    try {
      await axios
        .post("https://api.netlify.com/build_hooks/65003bf32ec0d94d6bcd0369", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "true",
          },
        })
        .then((response) => {
          console.log(response);
          setDisableButtonPreview(true);
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error);
        });
    } catch (error) {
      console.error("An unexpected error happened:", error.message);
    }
  }

  async function sendBuild() {
    try {
      await axios
        .post(
          "https://api.netlify.com/build_hooks/65003a65a1bc444a4caec3d7",
          // {
          //   event_type: "downloaded_certificate",
          //   user_id: username,
          // },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "true",
            },
          }
        )
        .then((response) => {
          console.log(response);
          setDisableButtonBuild(true);
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error);
        });
    } catch (error) {
      console.error("An unexpected error happened:", error.message);
    }
  }

  return (
    <div>
      <div className="flex p-3 pb-0">
        {/* <p className="p-3 pb-0">
          Avert.info - Use the following action buttons to generate a preview
          and build
        </p> */}
        <p className="p-3 pb-0">
          Boost Web App - Use the following action buttons to generate a preview
          and build
        </p>
      </div>
      <div className="flex p-3">
        <div className="flex p-3">
          <button
            onClick={() => sendPreview()}
            className={
              disableButtonPreview
                ? "disabled bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            }
          >
            UAT preview
          </button>
        </div>

        <div className="flex p-3">
          <button
            onClick={() => sendBuild()}
            // className={"bg-gray-300 text-white font-bold py-2 px-4 rounded"}
            className={
              disableButtonBuild
                ? "disabled bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                : "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            }
          >
            Build
          </button>
        </div>
      </div>

      <div className="flex p-3 pb-0 pt-0">
        <p className="p-3 pb-0 pt-0">
          Preview is available at:{" "}
          <a
            className="font-semibold text-blue-600"
            href="https://uat--boost-uat.netlify.app"
            target="_blank"
          >
          uat--boost-uat.netlify.app
          </a>
        </p>
      </div>

      <div className="flex p-3 pb-0 pt-0">
        <p className="p-3 pb-0 pt-0">
          (refresh the page after use to use again)
        </p>
      </div>
    </div>
  );
};

export default IndexPage;
